import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLayout } from "../components/page-layout";
import { NavBar } from "src/components/navigation/nav-bar";
import { Link as MuiLink, Typography } from '@mui/material';
import Box from "@mui/material/Box";

export const CallbackPage: React.FC = () => {
  const { error } = useAuth0();

  const queryParameters = new URLSearchParams(window.location.search);
  const callbackError = queryParameters.get("error");
  const callbackErrorDescription = queryParameters.get("error_description")
  
  if (callbackError === "access_denied" && callbackErrorDescription === "not_admin_approved") {
    return (
      <PageLayout>
        <Box 
          m="2em"
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Typography variant="h3" color="black">
            Requires Approval
          </Typography>
          <Typography color="black" fontSize="1.5em">
            Thanks for checking out my project! <MuiLink href="https://www.linkedin.com/in/seth-saperstein/" target="_blank">Ping me</MuiLink> for account approval!
          </Typography>
        </Box>
      </PageLayout>
    );
  } else if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <div className="page-layout">
      <NavBar />
      <div className="page-layout__content" />
    </div>
  );
};
